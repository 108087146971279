import { FC } from 'react';

import { CategoryTiles } from '../CategoryTiles/CategoryTiles';
import { CategoryRooms } from '../CategoryRooms/CategoryRooms';
import Error404Header from '../Error404Header/Error404Header';

export const Error404Layout: FC = () => {
    return (
        <>
            <Error404Header />
            <CategoryTiles />
            <CategoryRooms />
        </>
    );
};

export default Error404Layout;
