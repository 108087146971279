import 'dibs-buyer-layout/exports/error404';
import { initializeDBL } from 'dibs-buyer-layout/src/entries';
import * as tracking from 'dibs-tracking';

initializeDBL();

window.dataLayer.push({
    event: 'e_server_error',
    httpStatusCode: 404,
    pageRequestOrigin: document.location.href,
    pageRequestReferrer: document.referrer,
});

tracking.trackPageview({});
