import { FC, MouseEvent } from 'react';
import classnames from 'classnames';
import { Link } from 'dibs-elements/exports/Link';
import { useIntl, defineMessages, FormattedMessage } from 'dibs-react-intl';

import copy from './CategoryTilesCopy.json';
import styles from './CategoryTiles.scss';

const messages = defineMessages({
    paintings: { id: 'dbl.CategoryTiles.product_module.paintings', defaultMessage: 'Paintings' },
    prints: {
        id: 'dbl.CategoryTiles.product_module.prints_and_multiples',
        defaultMessage: 'Prints and Multiples',
    },
    clothing: {
        id: 'dbl.CategoryTiles.product_module.clothing',
        defaultMessage: 'Clothing',
    },
    handbags: {
        id: 'dbl.CategoryTiles.product_module.handbags_and_purses',
        defaultMessage: 'Handbags and Purses',
    },
    rings: {
        id: 'dbl.CategoryTiles.product_module.rings',
        defaultMessage: 'Rings',
    },
    watches: {
        id: 'dbl.CategoryTiles.product_module.watches',
        defaultMessage: 'Watches',
    },
    seating: {
        id: 'dbl.CategoryTiles.product_module.seating',
        defaultMessage: 'Seating',
    },
    decorativeObjects: {
        id: 'dbl.CategoryTiles.product_module.decorative_objects',
        defaultMessage: 'Decorative Objects',
    },
});

const productModules = {
    Art: [
        {
            url: '/art/paintings/',
            displayName: messages.paintings,
            image: 'https://a.1stdibscdn.com/anthony-hunter-1987-british-paintings-super-duper-pinky-yellow-scrape-with-dark-green-blob-on-top-painting-for-sale/a_271/a_78861521618323212491/Super_duper_pinky_yellow_scrape_with_dark_green_blob_on_top_painting_hi_res_master.jpg?width=240',
        },
        {
            url: '/art/prints-works-on-paper/',
            displayName: messages.prints,
            image: 'https://a.1stdibscdn.com/destro-photography-30x40-notorious-big-life-after-death-cassette-photography-pop-art-unsigned-for-sale/a_11302/a_153202621730303176143/BIG_master.jpg?width=240',
        },
    ],
    Fashion: [
        {
            url: '/fashion/clothing/',
            displayName: messages.clothing,
            image: 'https://a.1stdibscdn.com/archivesE/upload/v_13802/v_244551421730300998005/v_24455142_1730300998786_bg_processed.jpg?width=240',
        },
        {
            url: '/fashion/handbags-purses-bags/',
            displayName: messages.handbags,
            image: 'https://a.1stdibscdn.com/archivesE/upload/v_36322/v_244544721730297615981/v_24454472_1730297616604_bg_processed.jpg?width=240',
        },
    ],
    Jewelry: [
        {
            url: '/jewelry/rings/',
            displayName: messages.rings,
            image: 'https://a.1stdibscdn.com/natural-zambian-emerald-gemstone-ring-si-h-diamond-18-karat-white-gold-jewelry-for-sale/j_25702/j_242042121727688726761/j_24204212_1727688727151_bg_processed.jpg?width=240',
        },
        {
            url: '/jewelry/watches/',
            displayName: messages.watches,
            image: 'https://a.1stdibscdn.com/1980s-zenith-elite-automatic-18-karat-yellow-gold-wristwatch-for-sale/1121189/j_85381511579244972822/8538151_master.jpg?width=240',
        },
    ],
    Furniture: [
        {
            url: '/furniture/seating/',
            displayName: messages.seating,
            image: 'https://a.1stdibscdn.com/rounding-wood-stool-table-with-navy-lacquer-finish-by-debra-folz-for-sale/1121189/f_183817121584777459082/18381712_master.jpg?width=240',
        },
        {
            url: '/furniture/decorative-objects/',
            displayName: messages.decorativeObjects,
            image: 'https://a.1stdibscdn.com/handmade-vase-695-in-blue-with-blue-tencel-fringe-for-sale/1121189/f_228967721615621820122/22896772_master.jpeg?width=240',
        },
    ],
};

type Vertical = (typeof copy.items)[0];
type Modules = typeof productModules.Art;
export type OnClickFunction = (
    event: MouseEvent,
    options: { vertical: Vertical; index?: number }
) => void;
type Category = keyof typeof productModules;

export const TileMain: FC<{
    vertical: Vertical;
    onMainTileClick?: OnClickFunction;
}> = ({ vertical, onMainTileClick = () => {} }) => {
    const imageStyle = { backgroundImage: `url("${vertical.imageUrl}")` };
    return (
        <Link
            href={vertical.url}
            className={styles.tileLink}
            dataTn="category-main-tile-link"
            onClick={e => onMainTileClick(e, { vertical })}
        >
            <div className={styles.title}>
                <FormattedMessage
                    id="dbl.CategoryTiles.main_title"
                    defaultMessage={`
                        {type, select,
                            Furniture {Furniture}
                            Art {Fine Art}
                            Jewelry {Jewelry & Watches}
                            Fashion {Fashion}
                            Eero {Eero Saarinen}
                            Roy {Roy Lichtenstein}
                            Cartier {Cartier}
                            other {Hermès}
                        }
                    `}
                    values={{ type: vertical.category }}
                />
            </div>
            <div className={styles.imageWrap} style={imageStyle} />
        </Link>
    );
};

export const TileModules: FC<{
    vertical: Vertical;
    modules: Modules;
    onModuleTileClick?: OnClickFunction;
}> = ({ vertical, modules, onModuleTileClick = () => {} }) => {
    const intl = useIntl();

    return (
        <div className={styles.modules} data-tn="tile-modules">
            <div className={styles.tileSubTitle}>
                <FormattedMessage
                    id="dbl.CategoryTiles.module_title"
                    defaultMessage={`
                        {type, select,
                            Furniture {Popular in Furniture}
                            Art {Popular in Fine Art}
                            Jewelry {Popular in Jewelry & Watches}
                            other {Popular in Fashion}
                        }
                    `}
                    values={{ type: vertical.category }}
                />
            </div>
            <div className={styles.categoryProducts}>
                {modules.map((item, index) => {
                    const { url, image, displayName } = item;
                    const title = intl.formatMessage(displayName);
                    return (
                        <div className={styles.categoryProduct} key={`${index}${url}`}>
                            <Link
                                href={url}
                                title={title}
                                dataTn="category-module-tile-link"
                                onClick={e => onModuleTileClick(e, { vertical, index })}
                            >
                                <span className={styles.categoryProductImageWrap}>
                                    {image && (
                                        <img
                                            className={styles.categoryProductImage}
                                            src={item.image}
                                            alt={title}
                                        />
                                    )}
                                </span>
                                <span className={styles.categoryProductTitle}>{title}</span>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export const CategoryTiles: FC<{
    onMainTileClick?: OnClickFunction;
    onModuleTileClick?: OnClickFunction;
}> = ({ onMainTileClick, onModuleTileClick }) => {
    return (
        <div className={styles.tiles}>
            {copy.items.map(vertical => {
                const { type, category } = vertical;
                const isHorizontal = type === 'horizontal';
                const modules = productModules[category as Category] || [];

                const hasModules = modules && modules.length > 0;
                const tileClass = classnames({
                    [styles.tileSmall]: type === 'tile',
                    [styles.tileBig]: isHorizontal,
                });
                const tileSpacingClass = classnames(styles.tileSpacing, {
                    [styles.hasModules]: hasModules,
                });

                return (
                    <div className={tileClass} key={vertical.imageUrl}>
                        <div className={tileSpacingClass}>
                            <TileMain vertical={vertical} onMainTileClick={onMainTileClick} />
                            {hasModules && (
                                <TileModules
                                    modules={modules}
                                    vertical={vertical}
                                    onModuleTileClick={onModuleTileClick}
                                />
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
